import { createRoot } from 'react-dom/client';
import { BrowserRouter  } from 'react-router-dom';
import axios from 'axios';

import './i18n';
import App from './app/App';
import './app/App.css';
import { AuthProvider } from './app/contexts/JWTAuthContext';
import { SettingsProvider } from './app/contexts/SettingsContext';
import { ConfigProvider } from 'app/contexts/ConfigContext';
import { ForumProvider } from 'app/contexts/ForumContext';
import { AuthMethodsProvider } from 'app/contexts/AuthMethodsContext';

import { StyledEngineProvider } from '@mui/material';
import { AxiosInterceptor } from 'app/AxiosInterceptor';
import * as serviceWorker from './serviceWorker';

// third party style
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import 'react-dropzone-uploader/dist/styles.css';

// setup axios base rest api url
const { origin } = window.location
const default_rest_api = `${origin}`;
const rest_api = process.env.REACT_APP_REST_BASE || default_rest_api;
axios.defaults.baseURL = rest_api;

const root = createRoot(document.getElementById('root'));

root.render(
  <StyledEngineProvider injectFirst>
    <BrowserRouter>
      <SettingsProvider>
        <AuthMethodsProvider>
          <AuthProvider>
            <ConfigProvider>
              <ForumProvider>
                <App />
              </ForumProvider>
            </ConfigProvider>
            <AxiosInterceptor/>
          </AuthProvider>
        </AuthMethodsProvider>
      </SettingsProvider> 
    </BrowserRouter>
  </StyledEngineProvider>
);

// for IE-11 support un-comment cssVars() and it's import in this file
// and in MatxTheme file

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
