import { useState } from 'react';
import { nanoid } from 'nanoid/non-secure'
import * as qs from 'qs'

import { TableHead,Typography, Tooltip, useTheme, useMediaQuery } from '@mui/material';
import { Pause } from "@mui/icons-material";
import { red } from '@mui/material/colors';

import { BaseCard } from 'app/BRF/components';
import { AxiosPageTable } from 'app/BRF';
import { StyledTableCell, StyledTableRow } from 'app/BRF/styles';
import { i18capitalize, i18datetime } from 'app/utils/i18utils';
import { rstate } from './revisionActions'

const RevisionQueue = (props) => {
  const { title, states, 
          showRevision, setRevision, 
          fixedList, pagesize, 
          selectedRow: selectedRowFromProps, 
          setSelectedRow: setSelectedRowFromProps,
          rowsPerPageOptions=[10, 20, 30], 
          ...other } = props;

  const isControlled = typeof selectedRowFromProps !== 'undefined';
  const [ internalSelectedRow, internalSetSelectedRow ] = useState(-1);
  const default_pagesize = pagesize? pagesize : rowsPerPageOptions[0];

  const theme = useTheme();
  const shorten = useMediaQuery(theme.breakpoints.down('xl'));  

  const selectedRow = () => {
    if (!isControlled) return internalSelectedRow;
    else return selectedRowFromProps;
  };

  const setSelectedRow = (idx) => {
    if (!isControlled) internalSetSelectedRow(idx);
    else setSelectedRowFromProps(idx);
  };

  return (
    <BaseCard 
      title={title} 
      style={{ height: '100%' }} 
      {...other} 
    >
      <AxiosPageTable 
        prefix='RevisionQueue'
        fixedList={fixedList}
        pagesize={pagesize}

        query={{
          url: '/api/v1/assets/revisions',
          queryParams: {
            params: {
              states: states
            },
            
            paramsSerializer: params => {
              return qs.stringify(params, { arrayFormat: 'repeat' })
            },
          }
        }}

        //query={{url: '/api/v1/assets/revisions', queryParams: queryParams, queryParamStringifyOptions: {arrayFormat: 'repeat'}}}
        rowsPerPageOptions={rowsPerPageOptions}

        onSelect={(e, args) => {
          const { idx, entry } = args;
          setSelectedRow(idx);
          setRevision(entry);
        }}

        renderRow={({entry, idx, select}) => {
          if(idx < 0) return <StyledTableRow key={nanoid()} />;

          return (
            <StyledTableRow 
              key={`queue_${idx}`} 
              selected={showRevision?.id === entry.id || selectedRow() === idx} 
              onClick={e => select(e, {idx, entry})
              }
            >
              <StyledTableCell><Typography noWrap>{i18datetime('date', new Date(entry.created_on))}</Typography></StyledTableCell>
              <StyledTableCell><Typography noWrap>
                {shorten 
                ? entry?.company.name
                  .replace(/^(Bostadsföreningen )/,"")        // VERY!!! Sweedish..
                  .replace(/^(Bostadsrättsföreningen )/,"")
                : entry?.company.name
              }
                </Typography></StyledTableCell>
              <StyledTableCell>
                {
                  entry.on_hold  && 
                  <Tooltip title={i18capitalize('msg.job waiting for customer input', 'job waiting for customer input')} >
                    <Pause 
                      fontSize='0.1rem'
                      style={{
                        color: red[200]
                      }}
                      sz={{
                        padding: 0,
                        margin: 0
                      }}
                    />
                  </Tooltip>
                }
              </StyledTableCell>
              <StyledTableCell>
                <Typography noWrap>
                  {
                    (entry.state === rstate.REVISION_IN_PROGRESS && entry.assigned_user && (entry.assigned_user.fullname || entry.assigned_user.email)) || 
                    (entry.state === rstate.REVISION_IN_QUEUE && entry.eligeble_role && i18capitalize(`roles.${entry.eligeble_role.name}`, entry.eligeble_role.name)) ||
                    (entry.revisor_user?.fullname || entry.revisor_user?.email)
                  }
                </Typography>
              </StyledTableCell>
            </StyledTableRow>
          );
        }}
        >

        <colgroup>
            <col style={{width:'20%'}}/>
            <col style={{width:'45%'}}/>
            <col style={{width:'5%'}}/>
            <col style={{width:'30%'}}/>
        </colgroup>

        <TableHead>
            <StyledTableRow>
                <StyledTableCell>{i18capitalize(`common.date`, 'date')}</StyledTableCell>
                <StyledTableCell>{i18capitalize(`common.company`, 'company')}</StyledTableCell>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell>Assigned</StyledTableCell>
            </StyledTableRow>
        </TableHead>
      </AxiosPageTable>
    </BaseCard>
  );
};

export default RevisionQueue;
