import { createContext, useEffect, useReducer } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import jwt_decode from 'jwt-decode';

import { MatxLoading } from 'app/components';
import { enqueueSnackbar } from 'notistack'
import { i18capitalize } from 'app/utils/i18utils';

const initialState = {
  user: null,
  isInitialised: false,
  isAuthenticated: false
};

/*
  Moved to sessionStorage as per 
  https://hasura.io/blog/best-practices-of-using-jwt-with-graphql/
*/
const setSession = (access_token, refresh_token) => {
  if (access_token) {
    axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
    sessionStorage.setItem('access_token', access_token);
    sessionStorage.setItem('refresh_token', refresh_token);
  } else {
    delete axios.defaults.headers.common.Authorization;
    sessionStorage.removeItem('access_token');
    sessionStorage.removeItem('refresh_token');
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INIT': {
      const { isAuthenticated, user } = action.payload;
      return { ...state, isAuthenticated, isInitialised: true, user };
    }

    case 'LOGIN': {
      const { user } = action.payload;
      return { ...state, isAuthenticated: true, user };
    }

    case 'LOGOUT': {
      return { ...state, isAuthenticated: false, user: null };
    }

    default:
      return state;
  }
};

const AuthContext = createContext({
  ...initialState,
  method: 'JWT',
  secureLogin: () => {},
  login:  () => {},
  logout: () => {}
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();

  // Try to setup axios here...?

  const login = async (email, password) => {
    const response = await axios.post('/api/v1/login', { email: email, password: password }, {ignoreGlobalCatch: true});
    const user = response.data;
    user.decoded_token = jwt_decode(user?.access_token);
    setSession(user?.access_token, user?.refresh_token);
    dispatch({ type: 'LOGIN', payload: { user } });
  };

  const secureLogin = async (orderRef, csrfToken) => {
    console.log('secureLogin: ', orderRef);
    axios
      .get(`/api/v1/secure/${orderRef}`, {headers: { 'X-CSRFToken': csrfToken }})
      .then((res) => {
        console.log('secureLogin res: ', res);
        const user = res.data;
        user.decoded_token = jwt_decode(user?.access_token);
        setSession(user?.access_token, user?.refresh_token);
        dispatch({ type: 'LOGIN', payload: { user } });
      })
      .catch((err) => {
        //console.log('secureLogin err: ', err);
        const response = err.response;
        let message = i18capitalize('auth.user_not_found', 'User not found!');
        if(response.data.message)
          message = i18capitalize(`auth.${response.data.message}`, response.data.message);
        
        console.log('secureLogin msg: ', message);
        enqueueSnackbar(message, {variant: "error"});
        dispatch({ type: 'LOGOUT' });
      });
  };

  const logout = async () => {
    await axios.post('/api/v1/logout', {});
    setSession();
    dispatch({ type: 'LOGOUT' });
  };

  useEffect(() => {
    (async () => {
      try {
        const access_token = sessionStorage.getItem('access_token');
        if(access_token) setSession(access_token);
        const { data } = await axios.get('/api/v1/profile', {ignoreGlobalCatch: true});
        data.access_token = access_token;
        data.decoded_token = jwt_decode(access_token);
        dispatch({ type: 'INIT', payload: { isAuthenticated: true, user: data } });
      } catch (err) {
        console.error(err);
        dispatch({ type: 'INIT', payload: { isAuthenticated: false, user: null } });
      }
    })();
  }, []);

  useEffect(() => {
    if(state?.user !== null && state?.isAuthenticated){
      console.log(`User ${state.user.id} logged in, redirecting to /`);
      navigate('/');
    }
  }, [state]);

  // SHOW LOADER
  if (!state.isInitialised) return <MatxLoading />;

  return (
    <AuthContext.Provider value={{ ...state, method: 'JWT', secureLogin, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
