import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import AuthGuard from './auth/AuthGuard';
import { authRoles } from './auth/authRoles';   // XXX: Anacronism
import Loadable from './components/Loadable';
import MatxLayout from './components/MatxLayout/MatxLayout';
import materialRoutes from 'app/views/material-kit/MaterialRoutes';

// When this is include it doesnt crash, WHY?
// eslint-disable-next-line
import { default as XXX } from 'app/views/BRF/Dashboard_BRF';

// session pages
const NotFound          = Loadable(lazy(() => import('app/views/sessions/NotFound')));
const Login             = Loadable(lazy(() => import('app/views/sessions/Login')));
const Searcher          = Loadable(lazy(() => import('app/views/BRF/search')));

// dashboard page
const Dashboard         = Loadable(lazy(() => import('app/views/BRF/Dashboard')));

const DashboardBRF      = Loadable(lazy(() => import('app/views/BRF/Dashboard_BRF')));
const DashboardConsult  = Loadable(lazy(() => import('app/views/BRF/Dashboard_Consult')));
const DashboardSales    = Loadable(lazy(() => import('app/views/BRF/Dashboard_Sales')));

const RevisionProfile   = Loadable(lazy(() => import('app/views/BRF/Revision')));

const BoardUpdate      = Loadable(lazy(() => import('app/views/BRF/BoardUpdate')));

const RevisionSurvey    = Loadable(lazy(() => import('app/views/BRF/RevisionSurvey')));
const SurveyList        = Loadable(lazy(() => import('app/views/BRF/SurveyList')));

const CustomerV2Profile = Loadable(lazy(() => import('app/views/BRF/Customerv2')));
const CustomerV2List    = Loadable(lazy(() => import('app/views/BRF/Customerv2List')));
const SynaViewer        = Loadable(lazy(() => import('app/views/BRF/Syna')));

const UserV2           = Loadable(lazy(() => import('app/views/BRF/Userv2')));
const UserListV2       = Loadable(lazy(() => import('app/views/BRF/Userv2List')));

const NewsView         = Loadable(lazy(() => import('app/views/BRF/NewsView')));
const NewsList         = Loadable(lazy(() => import('app/views/BRF/NewsList')));

const ForumView         = Loadable(lazy(() => import('app/views/BRF/ForumView')));
const ForumList         = Loadable(lazy(() => import('app/views/BRF/ForumList')));

const NotifyList       = Loadable(lazy(() => import('app/views/BRF/NotifyList')));

// Under developement/test
const AccessView       = Loadable(lazy(() => import('app/views/BRF/AccessView')));
const AccessList       = Loadable(lazy(() => import('app/views/BRF/AccessList')));

// Phase out

const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      ...materialRoutes,
      { path: '/dashboard/default',   element: <Dashboard />,        auth: authRoles.admin }, // XXX: figure out authroles stuff...

      { path: '/dashboard/brf',       element: <DashboardBRF />,     auth: authRoles.admin },
      { path: '/dashboard/sales',     element: <DashboardSales />,   auth: authRoles.admin },
      { path: '/dashboard/consult',   element: <DashboardConsult />, auth: authRoles.admin },

      { path: '/revision/:id',        element: <RevisionProfile />,  auth: authRoles.admin },
      
      { path: '/board/:id',           element: <BoardUpdate /> },
      { path: '/board',               element: <BoardUpdate /> },

      { path: '/revision/survey/:id', element: <RevisionSurvey /> }, 
      { path: '/surveys',             element: <SurveyList /> },
      
      { path: '/syna/:method/:query', element: <SynaViewer /> },
      { path: '/syna',                element: <SynaViewer /> },

      { path: '/search',              element: <Searcher /> },

      { path: '/customers',           element: <CustomerV2List /> },
      { path: '/customer/:id',        element: <CustomerV2Profile /> },

      { path: '/profile/:id',         element: <UserV2 /> },
      { path: '/profile',             element: <UserV2 /> },
      { path: '/users',               element: <UserListV2 /> },

      { path: '/news/:id',            element: <NewsView /> },      // Move into forum
      { path: '/news',                element: <NewsList /> },

      { path: '/forum/:id',           element: <ForumView /> },     // View a forum post
      { path: '/forum',               element: <ForumList /> },     // View forum list

      { path: '/notifications',       element: <NotifyList /> },

      // Modules in Test/Developement
      { path: '/access/:id',           element: <AccessView /> },
      { path: '/access',               element: <AccessList /> },

      // Phase out
    ]
  },

  // session pages route
  { path: '/session/404',             element: <NotFound /> },
  { path: '/login',                   element: <Login /> },

  { path: '/',                        element: <Navigate to="dashboard/default" /> },
  { path: '*',                        element: <NotFound /> },
];

export default routes;
